import api from './api';
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
};
// const email = 'nq@godofx.com';
// const password = 'Godo@702';

export const authorization = () => {
    const encryptedData = encryptData({
        email: process.env.REACT_APP_USER,
        password: process.env.REACT_APP_PASSWORD,
    }, SECRET_KEY);
    return api.post('/v1/crm/auth/authLogin', { data: encryptedData });
};

export const authorizationCp = (email, password) => {
    return api.post('/v1/cp/auth/login', {
        email,
        password,
    });
};

export const postClient = (params) => {
    console.log("Post client details ", params);
    return api.post('/v1/crm/customers/registration', params);
};

export const updateClient = (id, params, rec) => {
    console.log('id data is ', id, params);
    return api.patch('/v1/crm/customers/registrationUpdate/' + id, params);
};

export const uploadDocuments = async (params) => {
    console.log('Upload Doc Files', params);
    return api.post('/v1/crm/documents', params,{
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
    );
}