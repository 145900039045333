import { Form } from "../../containers/stpmeRoute/Stpme";
import { useState } from "react";
import { Row } from "reactstrap";
import LogoImage from '../../template/svg/LogoDark.svg'
import { Container, Logo, LogoContainer, MainContainer } from "../App/styled";
import Flags from '../App/flagscsa';
import lockImage from '../../template/images/Lock.png';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PopAlert from "../App/PopAlert";

function StpmeSca() {
  const [flagData, setFlagData] = useState('FSC');
  const [show, setShow] = useState(false);
  const [backPage, setBackPage] = useState();
  const [entityName, setEntityName] = useState('');
  // Function to update flag data
  const updateFlagData = (newText) => {
    console.log('Entity is ', newText);
    setFlagData(newText);
  };

  return (
    <MainContainer>
      <Row className="header" style={{ padding: "20px 0px", margin: "0"}}>
        <LogoContainer>
          <Logo src={LogoImage} />
          <Flags updateFlagData={updateFlagData} entityName={setEntityName} mainPage={backPage} entityUpdate={setBackPage} popUp={setShow} />
        </LogoContainer>
      </Row>
      <Row>
        <PopAlert show={show} entity={setBackPage} entityName={entityName} entityChangeValue={flagData} popUp={setShow} />
      </Row>
      <Row className="top_security w-100">
        <p style={{ marginBottom: '0px', fontSize: '16px' }} className="top_sec_p">
          <img
            src={lockImage}
            style={{ marginTop: '-3px', width: '20px', marginRight: '15px' }}
            alt="Lock Icon"
          />
          This is a secure online registration
        </p>
      </Row>
      <Row className="mt_50 w-100" style={{ margin: "50px 0", marginBottom: "0px"}}>
        <h1 className="text-center fs-5 mb-4">Unlock the World of Opportunities: Begin Your Trading Journey Today!</h1>
        {flagData === 'FSA' ?
          <p className="headingBlock">
            <b>"GODO Capital Limited”</b> (FSA License No 26005) is an authorized and registered limited company
            under the laws of <b>Sain Vincent and the Grenadines.</b> We ensure that your account will be
            managed in full compliance with the rules and regulations set forth by the <b>Financial Services Authority (FSA)</b>."
          </p> : flagData === 'SCA' ?
            <p className="headingBlock">
                <b>"GODO Introduction Financial Services L.L.C."</b> (SCA License no: 20200000168) is a duly authorized and 
              registered limited liability company under the regulations of the <b>Securities Commodities Authorities (SCA) in the United Arab Emirates</b>. 
              We operate in an exclusive partnership with <b>GODO Limited (Mauritius)</b> to introduce clients to a range of financial services. It is important to note 
              that GODO Introduction does not have the authorization to hold client assets or funds. All services are strictly provided on an introduction basis.
            </p> :
            <p className="headingBlock">
              <b>"GODO LTD”</b> (FSC License No GB20025812) is an authorized and registered limited company under the laws of <b>Mauritius</b>.
              We ensure that your account will be managed in full compliance with the rules and regulations set forth
              by the <b>Financial Service Commission (FSC)</b>."
            </p>
        }
      </Row>
      <Row className="text-center w-100">
        <h1 className="secondary">Application Form</h1>
        <p className="primary fw-bold fs-6">You're Just a Step Away from Achieving Your Financial Goals... Dive In Now!</p>
      </Row>
      <Container>
        <Form entity={flagData} mainPage={backPage} />
      </Container>
      <div className="footer">
        <div>
          <p className="text-center">For assistance, please contact our Client Service Team at cs@godocm.com</p>
          <p>
            *Risk Warning: Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. The information on this site is not directed at residents of the United States and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. The domain www.godocm.com is operated by Godo Ltd, which is authorized and regulated by the Financial Service Commission ( FSC ) in Mauritius (License No GB20025812).
          </p>
        </div>
      </div>

      <ToastContainer />
    </MainContainer>
  );
}

export default StpmeSca;