import "react-step-progress-bar/styles.css";
import { ALL_STEPS_LIST, ALL_STEPS_LIST_Morocco } from "../../containers/Form/constants";
import './style.css'

const getStepLabel = (step) => {
    // Define your step labels here
    const stepLabels = {
        STEP_1: 'Basic Information',
        STEP_2: 'Personal and Financial',
        STEP_3: 'Trading Information',
        STEP_4: 'Declaration',
    };

    return stepLabels[step] || '';
};

const getStepLabelMorocco = (step) => {
    // Define your step labels here
    const stepLabels = {
        STEP_1: 'Basic Information',
        STEP_2: 'Personal and Financial',
        STEP_3: 'Trading Information',
    };

    return stepLabels[step] || '';
};

export const Progress = ({ currentStep, morocco }) => (
    <div className="flex center">
        {!morocco ? ALL_STEPS_LIST.map((step, index) => (
            <div
            key={index}
            className={`steps ${step.toLowerCase()} ${currentStep >= index + 1 ? 'darkStep' : 'lightStep'}`}
        >
            {getStepLabel(step)}
        </div>
        )) :
        ALL_STEPS_LIST_Morocco.map((step, index) => (
            <div
            key={index}
            className={`steps ${step.toLowerCase()} ${currentStep >= index + 1 ? 'darkStep' : 'lightStep'}`}
        >
            {getStepLabelMorocco(step)}
        </div>
        ))}
    </div>
);
