import React from 'react';

/*const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[@%&])[A-Za-z\d@%&]{8}$/;
    return regex.test(password);
}*/

const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
        errors.push("Password must be exactly or greater then 8 characters long.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
        errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[@#&$])/.test(password)) {
        errors.push("Password must contain one special character (@, #, & or $).");
    }
    if (!/^[A-Za-z\d@#&$]+$/.test(password)) {
        errors.push("Password can only contain letters, digits, and special characters (@, #, &, $).");
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}
const PasswordValidation = ({ password }) => {
   //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&])(?=.*[^a-zA-Z\d\s@%&]).{8,15}$/;
    //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&]).{8,8}$/;

    const isValid = validatePassword(password);
    if (isValid.isValid) {
        console.log("Password is valid.");
    } else {
        console.log("Password is invalid:", isValid.errors);
    }
    return (
        <div className="form-row passwordValid">
            <div className="form-holder">
                <h4>Password Should</h4>
                <span style={{ color: isValid ? 'green' : 'red', top: '6px', position: 'relative' }} className="password_warning"></span>
                <p id="letter" className={`${ /[a-z]/.test(password) ? 'valid' : 'invalid'}`}>
                    At least <b>1 lowercase</b> letter
                </p>
                <p id="capital" className={`${ /[A-Z]/.test(password) ? 'valid' : 'invalid'}`}>
                   <b>1 capital (uppercase)</b> letter
                </p>
                <p id="number" className={`${ /\d/.test(password) ? 'valid' : 'invalid'}`}>
                    At least <b>1 Number</b>
                </p>
                <p id="length" className={`${ password.length >= 8 ? 'valid' : 'invalid'}`}>
                    Minimum <b>8 characters</b>
                </p>
                <p id="maxlength" className={`${ password.length >= 8 && password.length <= 12 ? 'valid' : 'invalid'}`}>
                    Maximum <b>12 characters</b>
                </p>
                <p id="specialChar" className={`${ /[@#&$]/.test(password) ? 'valid' : 'invalid'}`}>
                    <b>1 Special characters ($,#,@,&)</b>
                </p>
            </div>
        </div>
    );
};

export default PasswordValidation;
