import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { Progress } from "../../../components/Progress/sca";
import { useForm } from "react-hook-form";
import { BUTTON_TEXT } from "../constants"
import { Button, CustomForm } from "../style"
import '../style.css';
import monthsData from '../../../components/date/date.json';
import yearData from '../../../components/date/year.json';
import annualIncome from '../../../components/json/annualIncome.json';
import netWorth from '../../../components/json/netWorth.json';
import jsonData from '../../../components/json/accountsType.json';
import countriesData from "../../../components/json/countriesStates.json";
import { toast } from "react-toastify";
import { clientService, activitiesServices } from "../../../services";
import TokenService from "../../../services/token.service";
import loader from "../../../assets/images/loader.gif";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PasswordValidation from "../passwordValidation";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { PiExclamationMarkDuotone } from "react-icons/pi";

// opt generate for firebase
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../../config/firebaseConfig";

import CryptoJS from "crypto-js";

import '../../../index.css';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const Form = ({ entity }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [token, setToken] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [formattedDate, setFormattedDate] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    //const [declaration, setDeclaration] = useState(false);
    const [check, setCheck] = useState(false);
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [rec, setRec] = useState({});
    const [nationality, setNationality] = useState(''); // Define nationality state
    const [tin, setTin] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [fileData, setFileData] = useState('');
    const [correctInfo, setCorrectInfo] = useState(false);
    const [checkBox, setCheckBox] = useState(false);
    const [tick, setTick] = useState('');
    const [entityValue, setEntityValue] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [morocco, setMorocco] = useState(false);
    const [singleClick, setSingleClick] = useState(false);
    // const [user, setUser] = useState('');
    // const [numberVerify, setNumberVeerify] = useState(false);

    const [ipInfo, setIpInfo] = useState(null);

    const {
        setValue,
        reset,
    } = useForm();

    // useEffect(() => {
    //     setFilteredAccountTypes(filterAccountTypes('MT4'));
    // }, [refValue]);

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=52a7645817b83a');
                const data = await response.json();
                setIpInfo(data);
            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        };

        fetchIPInfo();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        let ibUrl = window.location.origin + `/?ptr=${ptrParam}&rm=${refParam}`;

        if (ibUrl === `${window.location.origin}/?ptr=2000095497&rm=69`) {
            window.location.href = `https://signup.godocm.com/?entity=FSA_MA&utm_medium=godoweb_ma&ptr=2000095497`;
        }


    }, []);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        const entityParam = searchParams.get('entity');
        const platform = searchParams.get('platform');
        const utm = searchParams.get('utm_medium');

        if (entity === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entity === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

        setFilteredAccountTypes(filterAccountTypes('MT4'));
        // Parse the query parameters from the URL
        // Get the value of the 'ref' parameter

        if (entityParam) {
            setCurrentStep(1);
            formData.step1.country = '';
            formData.step2.idType = '';
            formData.step3.accountType = '';
            formData.step2.modeOfPayment = '';
        }

        // mobile app url
        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA' && (ipInfo !== null && ipInfo.country === 'MA')) {
            window.location.href = `https://signup.godocm.com/?entity=FSA_MA&utm_medium=godotrader_app&platform=MT5`;
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step3.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            formData.step3.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        //desktop url
        if ((utm === 'godoweb_ma' && !platform)) {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
            setPlatForm('MT5');
            formData.step3.platform = 'MT5';
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        if (platform === 'MT5' && utm === 'godoweb_ma' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step3.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        // Set the value to the state
        if (refParam) {
            setRefValue(refParam);
        }
        if (ptrParam && entityParam !== 'FSA_MA' && !utm) {
            setEntityValue(true)
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, [entity, morocco, utmMediumValue, ipInfo]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const step = name.split('.')[0]; // Extract step number from input name
        const field = name.split('.')[1]; // Extract field name from input name

        if (value !== '') {
            setFormData(prevData => ({
                ...prevData,
                [step]: {
                    ...prevData[step],
                    [field]: value
                }
            }));
        }
    };

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, parentId: ptrValue, entity: entityValue ? 'FSA' : entity, callStatus: 'New Lead', firstName: '', email: '', phone: '', country: '', countryCode: '', utmMedium: '' },
        // step2: { nationality: '', state: '', proofOfAddress: '', idType: '', passportIdNo: '', idIssuedPlace: '', dob: '', proofOfIdentity: '', residency: '', resState: '', resIdType: '', resIdNumber: '', resIdIssuePlace: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', sourceOfFunds: '', source_Of_Income: '', taxResidency: '', tin: '', ubo: Boolean, uboState: '', additional: '' },
        step2: { nationality: '', nationalityCode: '', state: '', address: '', proofOfAddress: '', idType: '', idTypeCode: '', passportIdNo: '', year: '', month: '', day: '', proofOfIdentity: '', proofOfIdentityBack: '', residency: '', residencyCode: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', modeOfPaymentCode: '', sourceOfFunds: '', sourceOfFundsCode: '', sourceOfIncome: '', taxResidency: '', taxIdentificationNumber: '', additional: '' },
        step3: { experiencedTrading: false, sharesFrequency: '', cfdFrequency: '', powerOfAttorney: '', learn: true, accountTradedByOthers: false, acceptTradingTerms: true, accountType: '', accountTypeCurrency: '', platform: 'MT4' },
        step4: { usCitizen: false, politicallyExposed: false, beneficialOwnerCode: '', declaration: Boolean, password: '', confirmPassword: '' }
        // Add more steps as needed
    });

    const data = {
        agentId: refValue || '69',
        parentId: ptrValue,
        entity: entityValue ? 'FSA' : entity,
        firstName: formData.step1.firstName,
        // middleName: formData.step1.middleName,
        // lastName: formData.step1.lastName,
        email: formData.step1.email,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        //mobile: formData.step1.phone
        utmMedium: utmMediumValue,


        nationality: formData.step2.nationality,
        nationalityCode: formData.step2.nationalityCode,
        //state: formData.step2.state,
        dob: formattedDate,
        //proofOfAddress: formData.step2.proofOfAddress,
        idType: formData.step2.idType,
        idTypeCode: formData.step2.idTypeCode,
        passportIdNo: formData.step2.passportIdNo,
        // idIssuedPlace: formData.step2.isIdIssuedPlace || nationality,
        proofOfIdentity: formData.step2.proofOfIdentity,
        proofOfIdentityBack: formData.step2.proofOfIdentityBack,
        residency: formData.step2.residency,
        residencyCode: formData.step2.residencyCode,
        //resState: formData.step2.resState,
        //resIdType: formData.step2.resIdType,
        //resIdNumber: formData.step2.resIdNumber,
        // resIdIssuePlace: formData.step2.resIdIssuePlace,
        resProofOfAddress: formData.step2.resProofOfAddress,
        annualIncome: formData.step2.annualIncome,
        netWorth: formData.step2.netWorth,
        modeOfPayment: formData.step2.modeOfPayment,
        modeOfPaymentCode: formData.step2.modeOfPaymentCode,
        sourceOfFunds: formData.step2.sourceOfFunds,
        sourceOfFundsCode: formData.step2.sourceOfFundsCode,
        sourceOfIncome: formData.step2.sourceOfIncome,
        taxResidency: formData.step2.taxResidency,
        taxIdentificationNumber: formData.step2.taxIdentificationNumber,
        //sameAsAbove: formData.step2.sameAsAbove,
        address: formData.step2.address,
        // ubo: formData.step2.ubo,
        // uboState: formData.step2.uboState,
        additional: formData.step2.additional,

        platform: formData.step3.platform,
        accountType: formData.step3.accountType,
        accountTypeCurrency: formData.step3.accountTypeCurrency,
        experiencedTrading: formData.step3.experiencedTrading,
        sharesFrequency: formData.step3.sharesFrequency,
        cfdFrequency: formData.step3.cfdFrequency,
        learn: formData.step3.learn,
        accountTradedByOthers: formData.step3.accountTradedByOthers,
        //powerOfAttorney: formData.step3.powerOfAttorney,
        acceptTradingTerms: formData.step3.acceptTradingTerms,

        usCitizen: formData.step4.usCitizen,
        politicallyExposed: formData.step4.politicallyExposed,
        beneficialOwnerCode: formData.step4.beneficialOwnerCode || 'N',
        declaration: true,
        password: formData.step4.password,

        accountStatus: 'New',
        isLead: false,
        isClient: true,
    }

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, otpValue: false, country: false, countryCode: false,
        },
        // step2: {
        //     nationality: false, state: false, proofOfAddress: false, idType: false, passportIdNo: false, idIssuedPlace: false, dob: false, proofOfIdentity: false, residency: false, resState: false, resIdType: false, resIdNumber: false, resIdIssuePlace: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, sourceOfFunds: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, ubo: false, uboState: false, additional: false
        // },
        step2: {
            nationality: false, nationalityCode: false, address: false, proofOfAddress: false, idType: false, idTypeCode: false, passportIdNo: false, dob: false, proofOfIdentity: false, proofOfIdentityBack: false, residency: false, residencyCode: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, modeOfPaymentCode: false, sourceOfFunds: false, sourceOfFundsCode: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, additional: false
        },
        step3: {
            experiencedTrading: false, sharesFrequency: false, cfdFrequency: false, learn: false, accountTradedByOthers: false, powerOfAttorney: false, acceptTradingTerms: true, platform: false, accountType: false,
        },
        step4: { usCitizen: true, politicallyExposed: true, beneficialOwnerCode: false, declaration: false, password: false, confirmPassword: false }
        // other steps...
    });

    const formValueChange = (step, name, value) => {
        // Regular expression for English letters and basic punctuation
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = ['proofOfIdentity', 'proofOfIdentityBack', 'resProofOfAddress', 'sourceOfIncome', 'additional', 'powerOfAttorney'];
        console.log('value: ', value);

        if (name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }

        if (name === 'phone') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value.replace(/[^\d]/g, ''),
                },
            }));
        } else if (name === 'dob') {
            console.log('bodData: ', value)
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        } else if (englishRegex.test(value)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        } else if (uploadedFileNames.includes(name)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        }

        console.log("Account Type", formData.step1);
        //console.log("formdataStep2", formData.step2);
        console.log("phone: ", formData.step1.phone);
        console.log("fileData: ", fileData && fileData);
    };

    const validatePass = (password) => {
        //const regex = /^(?=.*[A-Z])(?=.*[@%&])[A-Za-z\d@#&]{8}$/;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#&$]).{8,12}$/;
        return regex.test(password);
    }

    const validatePassword = (password) => {
        // Your password validation logic here
        // Example: At least 1 lowercase letter, 1 uppercase letter, 1 non-letter, 8-15 characters, 1 special character
        //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&]).{8,8}$/;
        const isValid = validatePass(password);
        if (isValid) {
            return true;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };


    const isStep1Valid = () => {
        // if (!validatePassword(formData.step1.password)) {
        //     toast.error("Password must be upper, lower and symbols($,&,@,#)");
        //     return false;
        // }
        // if (!(formData.step1.password === formData.step1.confirmPassword)) {
        //     toast.error("Password and confirm password must match");
        //     return false;
        // }
        const { firstName, email, phone, country } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        // const isAccountTypeValid = accountType !== '';
        // const isPasswordValid = password.trim() !== '';
        // const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();
        const isCountryValid = country !== '';
        const isNationalityValid = formData.step2.nationality !== '';

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                // accountType: !isAccountTypeValid,
                // password: !isPasswordValid,
                // confirmPassword: !isConfirmPasswordValid,
                country: !isCountryValid,
            },
            step2: {
                nationality: !isNationalityValid,
            }
        }));


        // Return true if all fields are valid, false otherwise
        // const validTrue = !morocco ? isfirstNameValid && isEmailValid && isNationalityValid && isphoneValid &&
        //     isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid : isfirstNameValid && isEmailValid && isphoneValid &&
        //     isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid;
        const validTrue = !morocco ? isfirstNameValid && isEmailValid && isNationalityValid && isphoneValid &&
            isCountryValid : isfirstNameValid && isEmailValid && isphoneValid &&
        isCountryValid;
        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        }
        return true;
    };

    const isStep2Valid = () => {
        const { nationality, nationalityCode, address, idType, passportIdNo, year, month, day, dob, proofOfIdentity, proofOfIdentityBack, residency, residencyCode, resProofOfAddress, annualIncome, netWorth, modeOfPayment, sourceOfFunds, source_Of_Income, taxResidency, taxIdentificationNumber, additional } = formData.step2;
        // Check each field individually and set their validity
        const isNationality = nationality !== '';
        const isNationalityCode = nationalityCode !== '';
        //const isStateValid = state !== '';
        const isAddress = address !== '';
        const isDob = dob !== '';
        const isYear = year !== '';
        const isMonth = month !== '';
        const isDay = day !== '';
        //const isproofOfAddress = proofOfAddress !== null;
        const isIdType = idType !== '';
        const isIdNumber = passportIdNo.trim() !== '';
        //const isIdIssuedPlace = idIssuedPlace !== '';
        const isproofOfIdentity = proofOfIdentity !== '';
        const isproofOfIdentityBack = proofOfIdentityBack !== '';
        const isResidency = residency !== '';
        const isResidencyCode = residencyCode !== '';
        //const isResState = resState !== '';
        //const isResIdType = resIdType !== '';
        //const isResIdNumber = resIdNumber.trim() !== '';
        //const isResIdIssuedPlace = resIdIssuePlace !== '';
        //const isresProofOfAddress = resProofOfAddress !== '';
        const isAnnualIncome = annualIncome !== '';
        const isNetWorth = netWorth !== '';
        const isModeOfPayment = modeOfPayment !== '';
        const issourceOfFunds = sourceOfFunds !== '';
        //const issource_Of_Income = source_Of_Income !== '';
        //const isTaxResidency = taxResidency !== '';
        //const istaxIdentificationNumber = taxIdentificationNumber.trim() !== '';
        let testResult = true;

        // Check if any uploaded files are the same
        const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress, source_Of_Income, additional];
        //const uploadedFileNames = ['proofOfIdentity', 'resProofOfAddress', 'source_Of_Income', 'additional'];

        if (proofOfIdentity === '' && morocco === false) {
            testResult = false; // Return false if any of the fields is empty
            toast.error('Please upload all required files');
        } else {
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        toast.error(`Uploaded files could not be the same`);
                        return testResult = false;
                    }
                }
            }

        }
        if (morocco === false ? isNationality && isNationalityCode && isYear && isMonth && isDay && isIdType && isIdNumber && isAddress && isproofOfIdentity && testResult
            && isResidency && isResidencyCode && isAnnualIncome && isNetWorth && isModeOfPayment && issourceOfFunds : isYear && isMonth && isDay && isAddress && testResult
            && isResidency && isResidencyCode) {
            return true;
        }
        else {
            toast.error("Fill all the required fields!");
            return false;
        }
    };

    const isStep3Valid = () => {
        const { experiencedTrading, accountTradedByOthers, acceptTradingTerms, sharesFrequency, cfdFrequency, powerOfAttorney, accountType } = formData.step3;

        const isExperiencedTrading = experiencedTrading === true || false;
        //const isProductName = productName !== '';
        // const isFrequency = frequency !== '';
        //const isWantToLearn = wantToLearn !== '';
        const isAccountTypeValid = accountType !== '';
        const isAccountTradedByOthers = accountTradedByOthers === true || false;
        const isPowerOfAttorney = powerOfAttorney !== '';
        const isSharesFrequency = sharesFrequency !== '';
        const isCfdFrequency = cfdFrequency !== '';
        const isAcceptTradingTerms = acceptTradingTerms === true || false;

        // Set the validation errors dynamically for each field
        if (isExperiencedTrading) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step3: {
                    sharesFrequency: !isSharesFrequency,
                    cfdFrequency: !isCfdFrequency,
                    powerOfAttorney: !powerOfAttorney,
                    accountType: !isAccountTypeValid
                }
            }));
        } else {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step3: {
                    accountType: !isAccountTypeValid
                }
            }));
        }

        if ((isExperiencedTrading ? isExperiencedTrading && isCfdFrequency && isSharesFrequency : !isExperiencedTrading) &&
            (isAccountTradedByOthers ? isAccountTradedByOthers && isPowerOfAttorney : !isAccountTradedByOthers) &&
            isAcceptTradingTerms && accountType) {
            return true;
        } else {
            //toast.error("Fill all the required fields!");
            return false;
        }
    };

    const isStep4Valid = () => {
        if (!validatePassword(formData.step4.password)) {
            toast.error("Password must be upper, lower and symbols($,&,@,#)");
            return false;
        }
        if (!(formData.step4.password === formData.step4.confirmPassword)) {
            toast.error("Password and confirm password must match");
            return false;
        }

        const { taxIdentificationNumber } = formData.step2;
        const { usCitizen, politicallyExposed, declaration, password, confirmPassword } = formData.step4;
        //resetForm();
        const isUsCitizen = usCitizen === true || false;
        const isPep = politicallyExposed === true || false;
        const isDeclaration = declaration === true;
        const isTaxIdentificationNumber = taxIdentificationNumber !== '';
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();

        // Set the validation errors dynamically for each field
        if (isUsCitizen) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step2: {
                    taxIdentificationNumber: !isTaxIdentificationNumber,
                }
            }));
        }

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step4: {
                ...prevErrors.step4,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
            }
        }));

        console.log("passowrd: ", isPasswordValid);

        if ((isUsCitizen ? isUsCitizen && isTaxIdentificationNumber : !isUsCitizen) && (isPep ? isPep : !isPep) && isPasswordValid && isConfirmPasswordValid) {
            return true;
        } else {
            if (isUsCitizen) {
                toast.error("Fill all the required fields!");
            }
            return false;
        }
    };

    const isStepCombineValid = () => {
        if (!validatePassword(formData.step4.password)) {
            toast.error("Password must be upper, lower and symbols($,&,@,#)");
            return false;
        }
        if (!(formData.step4.password === formData.step4.confirmPassword)) {
            toast.error("Password and confirm password must match");
            return false;
        }

        const { password, confirmPassword } = formData.step4;
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step4: {
                ...prevErrors.step4,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
            }
        }));

        if ( isPasswordValid && isConfirmPasswordValid) {
            return true;
        } else {
            return false;
        }

    };

    function formatDate(inputDate) {
        // Create a new Date object by parsing the input date string
        //console.log('Date format before ',inputDate);
        const date = new Date(inputDate);

        // Get the year, month, and day from the parsed date
        const year = date.getFullYear();
        // Month needs to be converted to a two-digit format
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        // Day also needs to be converted to a two-digit format
        const day = ('0' + date.getDate()).slice(-2);

        // Format the date and time as 'YYYY-MM-DD 4:00:00'
        const formattedDate = `${year}-${month}-${day} 4:00:00`;
        console.log('Date format after ', formattedDate);

        return formattedDate;
    }

    // const setupRecaptcha = async (e) => {
    //     e.preventDefault();
    //     console.log('click')
    //     try {
    //         const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    //             'size': 'invisible',
    //             'callback': (response) => {
    //                 console.log('reCAPTCHA resolved');
    //             }
    //         });
    //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    //         console.log("confirmationResult: ", confirmationResult);
    //         setUser(confirmationResult);
    //     } catch (error) {
    //         if (error.code === 'auth/too-many-requests') {
    //             console.error('Too many requests. Please try again later.');
    //             alert('You have made too many requests in a short period. Please try again later.');

    //             // Optionally disable the button to prevent further requests
    //             const sendOTPButton = document.getElementById('send-otp-button');
    //             sendOTPButton.disabled = true;
    //             setTimeout(() => {
    //                 sendOTPButton.disabled = false;
    //             }, 60000); // Disable for 1 minute
    //         } else {
    //             console.error('Error sending OTP:', error);
    //         }
    //     }
    // };

    // const handleSendOTP = async () => {
    //     //setupRecaptcha();
    //     try {
    //         const appVerifier = recaptchaVerifier;
    //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    //         console.log("confirmationResult: ", confirmationResult);
    //         setUser(confirmationResult);
    //         return confirmationResult;
    //         //setMessage('OTP sent successfully. Please enter the OTP received.');
    //     } catch (error) {
    //         console.error('Error sending OTP:', error);
    //         //setMessage('Failed to send OTP. Please try again.');
    //     }
    // };

    // const handleVerifyOTP = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const data = await user.confirm(otp)
    //         console.log("userData: ", data);
    //         toast.success('Mobile No Verified')
    //         setNumberVeerify(true);
    //         //setMessage('OTP verified successfully.');
    //     } catch (error) {
    //         console.error('Error verifying OTP:', error);
    //         //setMessage('Failed to verify OTP. Please try again.');
    //     }
    // };

    // Add a callback for when the reCAPTCHA is solved
    // window.recaptchaCallback = function () {
    //     console.log('reCAPTCHA resolved');
    // }

    // const handleChangeRecapha = (value) => {
    //     console.log("onChange prop - Captcha value:", value);
    // };

    const scrollToTopButton = () => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
    }

    // const handleDateChangeDatePicker = (date) => {
    //     const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    //     setSelectedDate(formattedDate);

    //     formValueChange('step2', 'dob', formattedDate); // Update form data
    //     console.log('bod: ', formData.step2.dob); // Verify date in console
    //   };

    // const checkEmailVerification = async (user) => {
    //     try {
    //         // Refresh user to get the latest user info
    //         await user.reload();

    //         if (user.emailVerified) {
    //             // Perform actions after email is verified
    //             toast.success('Email verified successfully!');
    //             setEmailVerify(true);
    //             // Example of performing actions
    //             const token = await user.getIdToken();
    //             const step1Data = { 
    //                 ...formData.step1, 
    //                 entity: entityValue ? 'FSA' : entity, 
    //                 agentId: refValue || '69', 
    //                 parentId: ptrValue, 
    //                 nationality: formData.step2.nationality, 
    //                 nationalityCode: formData.step2.nationalityCode 
    //             };
    //             delete step1Data.confirmPassword;
    //             clientService.postClient(step1Data, token)
    //                 .then((res) => {
    //                     if (res && res.data && res.data.message) {
    //                         toast.error(res.data.message); // Display the error message
    //                         setLoading(false);
    //                     } else {
    //                         toast.success('User Created Successfully!');
    //                         setCurrentStep(currentStep + 1);
    //                         setCheckEmail(true);
    //                         console.log('created user is ', res);
    //                         setUserLogin(res);
    //                         setRec({
    //                             ...res,
    //                             loading: false,
    //                         });
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     toast.error('An error occurred while processing your request'); // Default error message
    //                     setLoading(false);
    //                 });

    //             scrollToTopButton();
    //         } else {
    //             // Poll again after some time
    //             setTimeout(() => checkEmailVerification(user), 2000);
    //         }
    //     } catch (error) {
    //         console.error('Error checking email verification:', error);
    //         toast.error('Error checking email verification. Please try again.');
    //     }
    // };

    // const handleSignup = async (e) => {
    //     e.preventDefault();
    //     try {
    //         console.log('click email');
    //         const userCredential = await createUserWithEmailAndPassword(auth, formData.step1.email, formData.step1.password);
    //         const user = userCredential.user;
    //         await sendEmailVerification(user);

    //         checkEmailVerification(user);

    //     } catch (error) {
    //         console.log('Error verifying email: ', error);
    //         toast.error('Email verifycation failed')
    //     }
    // }

    // const handleResendOTP = async () => {
    //     try {
    //         if (!recaptchaResolved) {
    //             console.error('reCAPTCHA not resolved. Please solve reCAPTCHA.');
    //             return;
    //         }

    //         const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    //             'size': 'invisible',
    //             'callback': (response) => {
    //                 console.log('reCAPTCHA resolved');
    //                 setRecaptchaResolved(true);
    //             }
    //         });
    //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    //         console.log("Resent OTP confirmationResult: ", confirmationResult);
    //         toast.info('OTP Resent');
    //     } catch (error) {
    //         console.error('Error resending OTP:', error);
    //         toast.error('Error resending OTP. Please try again.');
    //     }
    // };

    const submitForm = async (e, action) => {
        e.preventDefault();

        if (!checkBox) {
            setCorrectInfo(true);
        }

        setCheck(true);
        if (action === 'next') {
            if (currentStep === 1) {
                const step1Data = { ...formData.step1, entity: entityValue ? 'FSA' : entity, agentId: refValue || '69', parentId: ptrValue, nationality: formData.step2.nationality, nationalityCode: formData.step2.nationalityCode, utmMedium: formData.step1.utmMedium };
                if (isStep1Valid() && checkBox === true && morocco === false) {
                    setCheck(false);
                    // Remove the confirmPassword field
                    // delete step1Data.confirmPassword;
                    // step1Data.phone = phoneNumber + step1Data.phone.slice(1);
                    //setCurrentStep(currentStep + 1);

                    if (checkEmail) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        clientService.postClient(step1Data, token)
                            .then((res) => {

                                if (res && res.data && res.data.message) {
                                    toast.error(res.data.message); // Display the error message
                                    setLoading(false);
                                } else {
                                    toast.success('User Created Successfully!');
                                    setCurrentStep(currentStep + 1);
                                    setCheckEmail(true);
                                    //setClientId(res.id);
                                    setUserLogin(res);
                                    setRec({
                                        ...res,
                                        loading: false,
                                    });
                                }

                            })
                            .catch((err) => {
                                toast.error('An error occurred while processing your request'); // Default error message
                                setLoading(false);
                            });
                        scrollToTopButton();
                    }
                } else if (isStep1Valid() && morocco === true) {
                    // Remove the confirmPassword field
                    setCheck(false);
                    // delete step1Data.confirmPassword;
                    // step1Data.phone = phoneNumber + step1Data.phone.slice(1);
                    //setCurrentStep(currentStep + 1);

                    if (checkEmail) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        clientService.postClient(step1Data, token)
                            .then((res) => {

                                if (res && res.data && res.data.message) {
                                    toast.error(res.data.message); // Display the error message
                                    setLoading(false);
                                } else {
                                    toast.success('User Created Successfully!');
                                    setCurrentStep(currentStep + 1);
                                    setCheckEmail(true);
                                    //setClientId(res.id);
                                    setUserLogin(res);
                                    setRec({
                                        ...res,
                                        loading: false,
                                    });
                                }

                            })
                            .catch((err) => {
                                toast.error('An error occurred while processing your request'); // Default error message
                                setLoading(false);
                            });
                        scrollToTopButton();
                    }
                } else {
                    setLoading(false);
                }
            }
            if (currentStep === 2) {

                const year = formData.step2.year;
                const month = formData.step2.month;
                const day = formData.step2.day;
                const date = formData.step2.year + '-' + formData.step2.month + '-' + formData.step2.day;
                console.log("data is ", date);
                // Check if all required date components are present
                if (year && month && day) {
                    // Construct the date string in YYYY-MM-DD format
                    const dateString = `${year}-${month}-${day}`;

                    // Format the date string
                    const formattedDateString = formatDate(dateString);

                    // Set the formatted date string to the state
                    setFormattedDate(formattedDateString);
                } else {
                    // If any of the required date components is missing, set the default date string
                    const defaultDateString = '1970-01-01 4:00:00';
                    setFormattedDate(defaultDateString);
                }

                if (isStep2Valid()) {
                    setCurrentStep(currentStep + 1);
                    scrollToTopButton();
                } else {
                    setCheck(true);
                    const date = formData.step2.year + '-' + formData.step2.month + '-' + formData.step2.day;
                    const formattedDateString = formatDate(date);
                    setLoading(false);

                    // Set the formatted date string to the state
                    setFormattedDate(formattedDateString);

                }
            }
            if (currentStep === 3) {
                if (isStep3Valid()) {
                    setCurrentStep(currentStep + 1);
                    scrollToTopButton();
                } else {
                    toast.error("Fill all the required fields!");
                    setLoading(false);
                }
            }
        } else if (action === 'previous') {
            setCheck(false);
            setCurrentStep(currentStep - 1);
            scrollToTopButton();
        } else {
            if (isStep4Valid() && !morocco) {
                const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log('reCAPTCHA resolved');
                    },
                    'expired-callback': () => {
                        console.log('reCAPTCHA expired');
                        toast.error('reCAPTCHA expired. Please try again.');
                    },
                    'error-callback': (error) => {
                        console.error('reCAPTCHA error:', error);
                        toast.error('reCAPTCHA verification failed. Please try again.');
                    }
                });

                const logData = {
                    type: 'userRegister',
                    customerId: userLogin.id,
                    note: ipInfo && JSON.stringify(ipInfo)
                }

                await activitiesServices.postActivity(logData);

                if (recaptchaVerifier && !singleClick) {
                    setSingleClick(true);
                    //Now, use the obtained token to make the postClient request
                    const formDataValue = new FormData();
                    formDataValue.append('customerId', userLogin.id);
                    imageList.forEach((item) => {
                        formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                    });

                    // clientService.uploadDocuments(formDataValue)
                    //     .then((res) => {
                    //         setRec({
                    //             ...res,
                    //             loading: false,
                    //         });

                    //         setLoading(true);
                    //         console.log(data, 'data');
                    //         data.acceptedTerms = true;
                    //         data.acceptedRisk = true;
                    //         data.acceptedPrivacy = true;
                    //         data.acceptedExecutionPolicy = true;
                    //         data.acceptedConflicts = true;
                    //         data.phone = phoneNumber + data.phone.slice(1);
                    //         clientService.updateClient(userLogin.id, data)
                    //             .then((res) => {
                    //                 setRec({
                    //                     ...res,
                    //                     loading: false,
                    //                 });

                    //                 clientService.authorizationCp(res.login.toString(), formData.step1.password).then((res) => {
                    //                     // Once authorization is successful, get the token
                    //                     const token = res.token;
                    //                     setLoading(false);

                    //                     //resetForm();
                    //                     // Construct the redirect URL with the token as a query parameter
                    //                     const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                    //                     // Redirect the user to the constructed URL
                    //                     window.location.href = redirectURL;
                    //                 });

                    //                 toast.success('Successfully Registered!');
                    //                 scrollToTopButton();
                    //             })
                    //             .catch((err) => {
                    //                 setLoading(false);
                    //                 toast.error('Account Registration failed, Contact with support!');
                    //             });

                    //     })
                    //     .catch((err) => {
                    //         toast.error('Upload documents again');
                    //     });

                    try {
                        // Upload documents
                        const uploadResponse = await clientService.uploadDocuments(formDataValue);
                        // Validate upload response
                        if (uploadResponse && uploadResponse.length !== 0) {
                            setRec({
                                ...uploadResponse,
                                loading: false,
                            });

                            // Set loading state
                            setLoading(true);

                            //Validate and update data
                            if (data) {
                                data.acceptedTerms = true;
                                data.acceptedRisk = true;
                                data.acceptedPrivacy = true;
                                data.acceptedExecutionPolicy = true;
                                data.acceptedConflicts = true;
                                data.phone = phoneNumber !== (null || undefined) ? phoneNumber + data.phone.slice(1) : data.phone;
                                delete formData.step4.confirmPassword;

                                try {
                                    // Update client information
                                    const updateResponse = await clientService.updateClient(userLogin.id, data);
                                    if (updateResponse && updateResponse.login) {
                                        setRec({
                                            ...updateResponse,
                                            loading: false,
                                        });

                                        // Authorize CP
                                        const convertIntoString = updateResponse.login.toString();
                                        const authResponse = await clientService.authorizationCp(convertIntoString, formData.step4.password);
                                        if (authResponse && authResponse.token) {
                                            // Get the token
                                            const token = authResponse.token;
                                            setLoading(false);

                                            // Construct the redirect URL with the token as a query parameter
                                            const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                                            // Redirect the user to the constructed URL
                                            window.location.href = redirectURL;

                                            toast.success('Successfully Registered!');
                                            scrollToTopButton();
                                        } else {
                                            throw new Error('Authorization failed');
                                        }
                                    } else {
                                        toast.error('Update client failed!');
                                        throw new Error('Update client failed');
                                    }
                                } catch (updateError) {
                                    setLoading(false);
                                    toast.error('Account Registration failed, Contact support!');
                                    console.log('Error updating client:', updateError);
                                    console.error('Error updating client:', updateError);
                                }
                            } else {
                                throw new Error('Data validation failed');
                            }
                        } else {
                            throw new Error('Document upload failed');
                        }
                    } catch (uploadError) {
                        setLoading(false);
                        toast.error('Upload documents again');
                        console.error('Error uploading documents:', uploadError.message);
                    }


                }

            } else if (morocco && isStepCombineValid() && isStep3Valid() ) {

                const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log('reCAPTCHA resolved');
                    },
                    'expired-callback': () => {
                        console.log('reCAPTCHA expired');
                        toast.error('reCAPTCHA expired. Please try again.');
                    },
                    'error-callback': (error) => {
                        console.error('reCAPTCHA error:', error);
                        toast.error('reCAPTCHA verification failed. Please try again.');
                    }
                });

                const logData = {
                    type: 'userRegister',
                    customerId: userLogin.id,
                    note: ipInfo && JSON.stringify(ipInfo),
                }

                await activitiesServices.postActivity(logData);

                if (recaptchaVerifier && !singleClick) {
                    setSingleClick(true);
                    //Now, use the obtained token to make the postClient request
                    const formDataValue = new FormData();
                    formDataValue.append('customerId', userLogin.id);
                    imageList.forEach((item) => {
                        formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                    });

                    console.log('completeData: ', data);

                    try {

                        if (imageList.length !== 0) {
                            // Upload documents
                            const uploadResponse = await clientService.uploadDocuments(formDataValue);
                            // Validate upload response
                            if (uploadResponse && uploadResponse.length !== 0) {
                                setRec({
                                    ...uploadResponse,
                                    loading: false,
                                });

                                // Set loading state
                                setLoading(true);

                                //Validate and update data
                                if (data) {
                                    data.acceptedTerms = true;
                                    data.acceptedRisk = true;
                                    data.acceptedPrivacy = true;
                                    data.acceptedExecutionPolicy = true;
                                    data.acceptedConflicts = true;
                                    delete formData.step4.confirmPassword;
                                    data.phone = phoneNumber !== (null || undefined) ? phoneNumber + data.phone.slice(1) : data.phone;

                                    try {
                                        // Update client information
                                        const updateResponse = await clientService.updateClient(userLogin.id, data);
                                        if (updateResponse && updateResponse.login) {
                                            setRec({
                                                ...updateResponse,
                                                loading: false,
                                            });

                                            // Authorize CP
                                            const convertIntoString = updateResponse.login.toString();
                                            const authResponse = await clientService.authorizationCp(convertIntoString, formData.step4.password);
                                            if (authResponse && authResponse.token) {
                                                // Get the token
                                                const token = authResponse.token;
                                                setLoading(false);

                                                // Construct the redirect URL with the token as a query parameter
                                                const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                                                // Redirect the user to the constructed URL
                                                window.location.href = redirectURL;

                                                toast.success('Successfully Registered!');
                                                scrollToTopButton();
                                            } else {
                                                throw new Error('Authorization failed');
                                            }
                                        } else {
                                            toast.error('Update client failed!');
                                            throw new Error('Update client failed');
                                        }
                                    } catch (updateError) {
                                        setLoading(false);
                                        toast.error('Account Registration failed, Contact support!');
                                        console.log('Error updating client:', updateError);
                                        console.error('Error updating client:', updateError);
                                    }
                                } else {
                                    throw new Error('Data validation failed');
                                }
                            } else {
                                throw new Error('Document upload failed');
                            }
                        } else {
                            //Validate and update data
                            if (data) {
                                data.acceptedTerms = true;
                                data.acceptedRisk = true;
                                data.acceptedPrivacy = true;
                                data.acceptedExecutionPolicy = true;
                                data.acceptedConflicts = true;
                                delete formData.step4.confirmPassword;
                                data.phone = phoneNumber !== (null || undefined) ? phoneNumber + data.phone.slice(1) : data.phone;

                                try {
                                    // Update client information
                                    const updateResponse = await clientService.updateClient(userLogin.id, data);
                                    if (updateResponse && updateResponse.login) {
                                        setRec({
                                            ...updateResponse,
                                            loading: false,
                                        });

                                        // Authorize CP
                                        const convertIntoString = updateResponse.login.toString();
                                        const authResponse = await clientService.authorizationCp(convertIntoString, formData.step4.password);
                                        if (authResponse && authResponse.token) {
                                            // Get the token
                                            const token = authResponse.token;
                                            setLoading(false);

                                            // Construct the redirect URL with the token as a query parameter
                                            const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                                            // Redirect the user to the constructed URL
                                            window.location.href = redirectURL;

                                            toast.success('Successfully Registered!');
                                            scrollToTopButton();
                                        } else {
                                            throw new Error('Authorization failed');
                                        }
                                    } else {
                                        toast.error('Update client failed!');
                                        throw new Error('Update client failed');
                                    }
                                } catch (updateError) {
                                    setLoading(false);
                                    toast.error('Account Registration failed, Contact support!');
                                    console.log('Error updating client:', updateError);
                                    console.error('Error updating client:', updateError);
                                }
                            } else {
                                throw new Error('Data validation failed');
                            }
                        }

                    } catch (uploadError) {
                        setLoading(false);
                        toast.error('Upload documents again');
                        console.error('Error uploading documents:', uploadError.message);
                    }


                }


            } else {
                // if (formData.step4.declaration !== true) {
                //     toast.error('Please accept Declaration!');
                // }
                setLoading(false);
            }
        }
    };

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;

            setImageList(updatedList);
            console.log('file: ', imageList)
        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
            console.log('file: ', imageList)
        }
    };
    const filterAccountTypes = (platform) => {
        console.log("ref value", refValue);

        if (ptrValue && refValue !== 38 && !morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 1))
        } else if (ptrValue && refValue !== 38 && morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 3))
        } else if (refValue === 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else if (morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 3))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = morocco === false ? platform === 'MT5' ? '2' : '14' : platform === 'MT5' ? '42' : '38';

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step3,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

        console.log("Account Type Setting", formData.step1);
    }

    console.log('password error: ', validationErrors.step4.password);

    return (
        <div style={{ overflow: "hidden" }}>
            <div id="recaptcha-container" style={{ display: 'none' }}></div>
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    <div className="flex-center py-10 RSPBprogressBars d-sms-none">
                        <Progress currentStep={currentStep} morocco={morocco} />
                    </div>
                    <CustomForm>
                        {currentStep === 1 &&
                            <div className={`justify ${morocco ? 'w-100' : ''}`}>
                                {/* <InfoText /> */}
                                <Row>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Ibid</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="agentId" type="text"
                                                    required="" placeholder="John"
                                                    value={refValue}
                                                    onChange={(e) => formValueChange('step1', 'agentId', refValue)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Entity</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="entity" type="text"
                                                    required="" placeholder="John"
                                                    value={entity}
                                                    onChange={(e) => formValueChange('step1', 'entity', entity)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={morocco ? 6 : 4}>
                                        <FormGroup>
                                            <Label>Full Name ( as per your passport or national ID )</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                                    name="firstName" type="text"
                                                    required="" placeholder="John"
                                                    value={formData.step1.firstName}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', 'firstName', capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col className="mobileView" md={12}>
                                        <Label style={{ color: 'gray' }}> *Please enter your name as per your passport or national ID</Label>
                                    </Col>
                                    <Col md={morocco ? 6 : 4}>
                                        <FormGroup>
                                            <Label>Email Address</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="email"
                                                    disabled={checkEmail ? true : false}
                                                    validation={{ required: true }}
                                                    value={formData.step1.email}
                                                    invalid={emailError === 1 && formData.step1.email !== '' && 1 || validationErrors.step1.email}
                                                    valid={emailError === 0 && formData.step1.email !== '' && 1 || !validationErrors.step1.email}
                                                    placeholder="john@gmail.com"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {!morocco && <Col md={4}>
                                        <Label>Nationality</Label>
                                        <Input
                                            className={"mb-3 " + (!validationErrors.step2.nationality || formData.step2.nationality ? 'is-valid' : 'is-invalid')}
                                            type="select"
                                            name="nationality"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                const selectedCountry = countriesData.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                if (selectedCountry) {
                                                    console.log("lksadjfkl country ", selectedCountry.name);
                                                    //setCountryid(selectedCountryName);
                                                    //setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                    formValueChange('step2', 'nationality', selectedCountry.name);
                                                    formValueChange('step2', 'nationalityCode', selectedCountry.iso2);
                                                    // Reset the selected state and city
                                                    // setStateid('');
                                                    // setCityList([]);
                                                    // formValueChange('step2', 'idIssuedPlace', selectedCountry.name); // Update form data with the selected country name
                                                    //setNationality(selectedCountry.name); // Set nationality
                                                    // GetState(selectedCountry.id).then((result) => {
                                                    //     setStateList(result); // Update the state list based on the selected country
                                                    // });
                                                }
                                            }}
                                            value={formData.step2.nationality} // Set the value to the selected country name
                                        >
                                            <option value="">-- Select --</option>
                                            {countriesData.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>}
                                    <Col className="DesktopView" md={12}>
                                        <Label style={{ color: 'gray' }}> *Please enter your name as per your passport or national ID</Label>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Middle Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.middleName || formData.step1.middleName) ? 'is-valid' : 'is-invalid')}
                                                    name="middleName" type="text"
                                                    required="" placeholder="Reo"
                                                    value={formData.step1.middleName}
                                                    onChange={(e) => formValueChange('step1', 'middleName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.lastName || formData.step1.lastName) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="lastName"
                                                    value={formData.step1.lastName}
                                                    required="" placeholder="Doe"
                                                    onChange={(e) => formValueChange('step1', 'lastName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={12}>
                                        <Row>
                                            <Col md={morocco ? 12 : 8}>
                                                <Row>
                                                    {/* <Col md={6} style={{ display: 'none' }}>
                                                        <FormGroup>
                                                            <Label>Platform</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step1.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step1.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step1', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    {morocco && formData.step1.utmMedium === 'godoweb_ma' && <option value="MT4">MT4</option>}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col> */}
                                                    {/* <Col md={6} style={{ display: 'none' }}>
                                                        <FormGroup>
                                                            <Label>Account Type</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step1.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step1', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col> */}
                                                    {/* <Col md={6} className="position-relative" style={{ display: 'none' }}>
                                                        <FormGroup>
                                                            <Label>Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative" style={{ display: 'none' }}>
                                                        <FormGroup>
                                                            <Label>Confirm Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + (validationErrors.step1.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>Country Of Residence</Label>
                                                            <Input
                                                                className={"mb-3 " + (!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid')}
                                                                type="select"
                                                                name="country"
                                                                onChange={(e) => {
                                                                    const selectedCountryName = e.target.value;
                                                                    const selectedCountry = countriesData.find(country => country.name === selectedCountryName);
                                                                    if (selectedCountry) {
                                                                        setCountryid(selectedCountry.name);
                                                                        setStateList(selectedCountry.states);
                                                                        //setMainCountry(selectedCountry.id);
                                                                        setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                                        formValueChange('step1', 'country', selectedCountry.name);
                                                                        formValueChange('step1', 'countryCode', selectedCountry.iso2);
                                                                        formValueChange('step2', 'residency', selectedCountry.name);
                                                                        formValueChange('step2', 'residencyCode', selectedCountry.iso2);
                                                                        setNationality(selectedCountry.name); // Set nationality
                                                                        // GetState(selectedCountry.id).then((result) => {
                                                                        //     setStateList(result);
                                                                        //     setStateid('');
                                                                        //     setCityList([]);
                                                                        // });
                                                                    }
                                                                }}
                                                                value={formData.step1.country}
                                                            >
                                                                <option value="">-- Select --</option>
                                                                {countryList.map((item, index) => (
                                                                    <option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="phoneNum">
                                                        <Label>Phone No</Label>
                                                        <IntlTelInput
                                                            name="phone"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                            style={{ width: '100%' }}
                                                            maxLength={15}
                                                            format="true"
                                                            autoFormat={false}
                                                            separateDialCode={false}
                                                            disabled={checkEmail ? true : false}
                                                            defaultCountry={selectedCountryCode || 'ae'}
                                                            useMobileFullscreenDropdown={false}
                                                            value={formData.step1.phone}
                                                            // onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                            //     setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                            // }}
                                                            onPhoneNumberChange={(status, value, countryData, number, isValidNumberPrecise, id, fullNumber) => {
                                                                // let formattedPhoneNumber = "+" + countryData.dialCode + value.slice(1).replace(/\s+/g, '');
                                                                // const isValid = isValidNumberPrecise;
                                                                // console.log('dialData: ', countryData);
                                                                // if (!isValid) {
                                                                //     // Phone number is invalid
                                                                //     console.log('Phone number is invalid');
                                                                //     // You can also set an error state or display an error message here
                                                                //   }
                                                                if (value.length < 15) {
                                                                    setPhoneNumber(countryData.dialCode);
                                                                    formValueChange('step1', 'phone', value);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {/* <Col md={4} style={{ display: 'none' }}>
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step1.password} />
                                                </FormGroup>
                                            </Col> */}
                                            {/* <Col md={12}>
                                                <Row>
                                                    <Col md={4} className="phoneNum">
                                                        <Label>Phone No</Label>
                                                        <IntlTelInput
                                                            name="phone"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                            style={{ width: '100%' }}
                                                            maxLength={15}
                                                            format="true"
                                                            disabled={numberVerify ? true : false}
                                                            defaultCountry={selectedCountryCode || 'ae'}
                                                            useMobileFullscreenDropdown={false}
                                                            value={formData.step1.phone}
                                                            onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                                setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                            }}
                                                            onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                                let formattedPhoneNumber = "+" + countryData.dialCode + value.slice(1).replace(/\s+/g, '');
                                                                setPhoneNumber(formattedPhoneNumber);
                                                                formValueChange('step1', 'phone', value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Button id="send-otp-button" disabled={numberVerify ? true : false} className='primary-bg-color col buttonWidth mb-0' onClick={!user ? setupRecaptcha : handleVerifyOTP} style={{ height: '45px', width: '350px', marginTop: '28px' }}>{user ? 'VerifyOTP' : 'SendOTP'}</Button>
                                                        <p onClick={handleResendOTP} disabled={numberVerify ? true : false} className="m-0 ms-3 text-primary fs-6 cursor" style={{ cursor: 'pointer'}}>OTP Resend</p>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Label>OTP Verification</Label>
                                                        <div className="input-group">
                                                            <Input className={"form-control " + ((!validationErrors.otpValue || otp) ? 'is-valid' : 'is-invalid')}
                                                                type="text"
                                                                name="lastName"
                                                                value={otp}
                                                                disabled={numberVerify ? true : false}
                                                                placeholder="Pin no"
                                                                onChange={(e) => setOtp(e.target.value)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            {!morocco && <Col md={12}>
                                                {!correctInfo ?
                                                    <div className={`input-group mb-3 mt-4 text-primary`}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="correctInfo"
                                                                checked={Boolean(checkBox)}
                                                                onChange={(e) => {
                                                                    setCheckBox(!checkBox)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp; The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.
                                                        </label>
                                                    </div>
                                                    :
                                                    <div className={`input-group mb-3 mt-4 ${checkBox === true ? 'text-primary' : 'text-danger'}`}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="correctInfo"
                                                                checked={Boolean(checkBox)}
                                                                onChange={(e) => {
                                                                    setCheckBox(!checkBox)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp; The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.
                                                        </label>
                                                    </div>
                                                }
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="justify w_100" style={{ margin: "0px" }}>
                                <Row>
                                    <Col md={12} className="mt-3"><h5>Personal Information</h5></Col>
                                    <Col md={ morocco ? 6 : 4 }>
                                        <FormGroup>
                                            <Label>Country Of Residence</Label>
                                            <Input
                                                className={"mb-3 " + (check && !formData.step2.residency && 'is-invalid')}
                                                type="select"
                                                name="residency"
                                                disabled // Disable the input
                                                onChange={(e) => {
                                                    const selectedresidencyName = e.target.value;
                                                    const selectedresidency = countriesData.find(country => country.name === selectedresidencyName);
                                                    if (selectedresidency) {
                                                        setStateList(selectedresidency.states);
                                                        setStateid(selectedresidency.name);
                                                        setNationality(selectedresidency.name)
                                                        //formValueChange('step2', 'sameAsAbove', false);
                                                        formValueChange('step2', 'residency', selectedresidency.name);
                                                        formValueChange('step2', 'residencyCode', selectedresidency.iso2);
                                                        //formValueChange('step2', 'resIdIssuePlace', selectedresidency.name); // Set the ID Issued Place same as Residency
                                                        // GetState(selectedresidency.id).then((result) => {
                                                        //     setResidencyStateList(result); // Update the separate state list based on the selected residency
                                                        // });
                                                    }
                                                }}
                                                value={formData.step2.residency || formData.step1.country}
                                            >
                                                <option value="">-- Select --</option>
                                                {countryList.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md={4} style={{ display: 'none' }}>
                                        <Label>State</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.state && "is-invalid")}
                                            name="state"
                                            type="select"
                                            onChange={(e) => {
                                                const selectedStateName = e.target.value; // Get the selected state name
                                                const selectedState = stateList.find(state => state.name === selectedStateName); // Find the state object based on the name
                                                if (selectedState) {
                                                    setStateid(selectedState.id); // Update the selected state id
                                                    // GetCity(selectedCountryCode, selectedState.iso2).then((result) => {
                                                    //     console.log('Cityes is', result);
                                                    //     setCityList(result); // Update the city list based on the selected state
                                                    // });
                                                    formValueChange('step2', e.target.name, selectedState.name); // Update form data with the selected state name
                                                }

                                                console.log('city ', cityList, countryid, selectedState.id)
                                            }}
                                            value={stateList.find(state => state.id === stateid)?.name}
                                        >
                                            <option value="">-- Select State --</option>
                                            {stateList.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col> */}
                                    <Col md={ morocco ? 6 : 4 }>
                                        <Label>Current / Permanent Address</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.address && 'is-invalid')}
                                                name="address" type="text"
                                                value={formData.step2.address}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="House No - Street - City - State" />
                                        </div>
                                    </Col>
                                    {/* <Col md={4}>
                                        <Label>Date of Birth ( as per the given ID )</Label>
                                        <div className="input-group mb-3">
                                            {/* <PikadayCustom 
                                               value={selectedDate} 
                                               maxDate={new Date(2008, 11, 31)} // December 31, 2008 
                                               onChange={handleDateChangeDatePicker} 
                                            /> */}
                                    {/* <DatePicker
                                                className="borderInput"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date("2008-12-31")}
                                                showYearDropdown
                                                scrollableYearDropdown
                                                selected={startDate}
                                                type="date"
                                                onChange={handleDateChange}
                                            /> */}
                                    {/* <Input
                                                className={"form-control " + (check && !formData.step2.dob && 'is-invalid')}
                                                name="dob"
                                                type="date"
                                                value={formData.step2.dob}
                                                required=""
                                                onChange={(e) => {
                                                    const dob = e.target.value;
                                                    formValueChange('step2', 'dob', dob);
                                                }}
                                                max={getMaxDOB()} // Setting the max attribute to ensure the user is at least 16 years old
                                            />
                                        </div>
                                    </Col> */}
                                    <Col md={4} style={{ display: 'none' }}>
                                        <Label for="proofOfAddress">
                                            Upload Document (Proof Of Address)
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} k />
                                                <span className="tooltiptext">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.proofOfAddress && "is-invalid")}
                                            type="file"
                                            name="proofOfAddress"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            onChange={(e) => {
                                                setFileData(e.target.value);
                                                updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                formValueChange('step2', e.target.name, e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {!morocco && <Row>
                                    <Col md={4}>
                                        <Label>ID Type</Label>
                                        {formData.step2.residency !== 'UNITED ARAB EMIRATES' ?
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + (check && !formData.step2.idType && 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.value !== '') {
                                                                formValueChange('step2', e.target.name, e.target.value)
                                                                if (e.target.value === 'EID-EMIRATES ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'EID')
                                                                } else if (e.target.value === 'PP-PASSPORT') {
                                                                    formValueChange('step2', 'idTypeCode', 'PP')
                                                                } else if (e.target.value === 'NID-NATIONAL ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'NID')
                                                                } else if (e.target.value === 'DL-DRIVING LICENSE') {
                                                                    formValueChange('step2', 'idTypeCode', 'DL')
                                                                } else if (e.target.value === 'RESIDENT PERMIT CARD') {
                                                                    formValueChange('step2', 'idTypeCode', 'RPC')
                                                                }
                                                            }
                                                        }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                    <option value="PP-PASSPORT">PP-PASSPORT</option>
                                                    <option value="NID-NATIONAL ID">NID-NATIONAL ID</option>
                                                    <option value="DL-DRIVING LICENSE">DL-DRIVING LICENSE</option>
                                                    <option value="RESIDENT PERMIT CARD">RESIDENT PERMIT CARD</option>
                                                </select>
                                            </div> :
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + (check && !formData.step2.idType && 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                            formValueChange('step2', 'idTypeCode', 'EID')
                                                        }
                                                    }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                </select>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        <Label>ID Number</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.passportIdNo && 'is-invalid')}
                                                name="passportIdNo" type="text"
                                                value={formData.step2.passportIdNo}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="ID Number" />
                                        </div>
                                    </Col>
                                    <Col md={4} style={{ display: "none" }}>
                                        <Label>ID Issued Place</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control "}
                                                // className={"form-control " + (check && !formData.step2.idIssuedPlace && 'is-invalid')}
                                                name="idIssuedPlace"
                                                type="text"
                                                value={nationality} // Set the value to nationality
                                                required=""
                                                disabled // Disable the input
                                                placeholder="UAE" />
                                        </div>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col md={4}>
                                        <Label for="proofOfIdentity" className="text-primary fw-bold">Upload Document (Proof Of Identity-Front)
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Please provide at least <b>ONE</b> of the following documents that
                                                    MUST have legal name, Not expired, date of birth, nationality, & registered ID number:
                                                    <ul>
                                                        <li>Passport - For any Nationality </li>
                                                        <li>Emirates ID, in case the client is UAE Resident </li>
                                                        <li>Any other Government Issued ID which has these credentials</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-1 " + (!morocco && check && !formData.step2.proofOfIdentity && "is-invalid")}
                                            type="file"
                                            name="proofOfIdentity"
                                            id="proofOfIdentity"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentity && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentity.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label for="proofOfIdentityBack" className="text-primary fw-bold">Upload Document (Proof Of Identity-Back)
                                        </Label>
                                        <Input
                                            className={"mb-1 "}
                                            type="file"
                                            name="proofOfIdentityBack"
                                            id="proofOfIdentityBack"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('PROOF_OF_ID_BACK', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentityBack && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentityBack.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label className="text-primary fw-bold">Upload Document (Proof Of Address)
                                            <div className="tooltip" style={{ opacity: '1', zIndex: '5', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextRight">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <div className="input-group mb-1">
                                            <Input
                                                className={"form-control"}
                                                name="resProofOfAddress"
                                                type="file"
                                                accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                onChange={(e) => {

                                                    const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                    const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                    const fileName = e.target.value;
                                                    const file = e.target.files[0];
                                                    const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                    if (file) {
                                                        if (file.size > maxFileSize) {
                                                            alert('File size exceeds the limit of 5 MB.');
                                                            e.target.value = '';
                                                            formValueChange('step3', e.target.name, e.target.value);
                                                        } else if (fileExtension.includes(fileExtensionCheck)) {
                                                            setFileData(e.target.value);
                                                            updateListFunction('RES_PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        } else {
                                                            alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                            e.target.value = '';
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData.step2.resProofOfAddress && <span className="fs-6">SelectedFile: {formData.step2.resProofOfAddress.slice(12, 24)}</span>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-4">
                                        <strong>Date of Birth ( as per the given ID )</strong>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Year</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.year && 'is-invalid')}
                                            name="year"
                                            type="select"
                                            value={formData.step2.year}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Year</option>
                                            {Object.keys(yearData).reverse().map((key) => (
                                                <option key={key} value={yearData[key].year}>{yearData[key].year}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Month</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.month && 'is-invalid')}
                                            name="month"
                                            type="select"
                                            value={formData.step2.month}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Month</option>
                                            {Object.keys(monthsData).map((key) => (
                                                <option key={key} value={key}>{monthsData[key].name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Day</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.day && 'is-invalid')}
                                            name="day"
                                            type="select"
                                            value={formData.step2.day}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Day</option>
                                            {formData.step2.month && [...Array(monthsData[formData.step2.month].days).keys()].map((day) => (
                                                <option key={day + 1} value={day + 1}>
                                                    {String(day + 1).padStart(2, '0')}
                                                </option>
                                            ))}


                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} style={{ display: "none" }} className="mt-3 flex-wrap align-items-center gap-3">
                                        <h5>Resident Status</h5>
                                        <FormGroup check style={{ paddingBottom: '0.1rem' }}>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={formData.step2.sameAsAbove}
                                                    onChange={() => {
                                                        const newValue = !formData.step2.sameAsAbove;
                                                        formValueChange('step2', 'sameAsAbove', newValue);
                                                        if (newValue) {
                                                            setIsCheck(true);

                                                            // if (formData.step2.nationality !== '') {
                                                            //     formValueChange('step2', 'residency', formData.step2.nationality);
                                                            // }

                                                            formValueChange('step2', 'residency', formData.step2.nationality);// Copy nationality to residency
                                                            formValueChange('step2', 'resState', formData.step2.state);
                                                            formValueChange('step2', 'resIdType', formData.step2.idType);
                                                            formValueChange('step2', 'resIdNumber', formData.step2.passportIdNo);

                                                            if (Array.isArray(imageList[0].value)) {
                                                                updateListFunction('RES_PROOF_OF_ADDRESS', [imageList[0].value[0]]);
                                                            }

                                                            // formValueChange('step2', 'resIdIssuePlace', formData.step2.nationality);
                                                            //formValueChange('step2', 'resProofOfAddress', formData.step2.proofOfAddress);
                                                            // GetState(countryList.find(country => country.name === formData.step2.nationality)?.id).then((result) => {
                                                            //     setResidencyStateList(result); // Update the separate state list based on the selected nationality
                                                            // });
                                                        }
                                                    }}
                                                />
                                                {' '} Same as above
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md={4}>
                                        
                                    </Col> */}

                                    {/* {formData.step2.sameAsAbove && (
                                        <>
                                            <Col md={4}>
                                                <Label>State</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + (check && !formData.step2.resState && 'is-invalid')}
                                                        name="resState"
                                                        type="select"
                                                        value={formData.step2.resState}
                                                        required=""
                                                        onChange={(e) => formValueChange('step2', 'resState', e.target.value)}
                                                    >
                                                        <option value="">-- Select State --</option>
                                                        {residencyStateList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>ID Type</Label>
                                                <div className="input-group mb-3">
                                                    <select
                                                        className={"form-control " + (check && !formData.step2.resIdType && 'is-invalid')}
                                                        name="resIdType"
                                                        value={formData.step2.resIdType}
                                                        required=""
                                                        onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}>
                                                        <option value="">-- Select ID Type --</option>
                                                        <option value="EID">EID-EMIRATES ID</option>
                                                        <option value="PP">PP-PASSPORT</option>
                                                        <option value="NID">NID-NATIONAL ID</option>
                                                        <option value="DL">DL-DRIVING LICENSE</option>
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>ID Number</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + (check && !formData.step2.resIdNumber && 'is-invalid')}
                                                        name="resIdNumber"
                                                        type="text"
                                                        value={formData.step2.resIdNumber}
                                                        required=""
                                                        onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                        placeholder="ID Number"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>ID Issued Place</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control "}
                                                        // className={"form-control " + (check && !formData.step2.resIdIssuePlace && 'is-invalid')}
                                                        name="resIdIssuePlace"
                                                        type="text"
                                                        value={formData.step2.residency}
                                                        required=""
                                                        disabled // Disable the input
                                                        placeholder="ID Issued Place"
                                                    />
                                                </div>
                                            </Col>
                                        </>
                                    )} */}
                                </Row>
                                {!morocco && <Row>
                                    <Col md={12} className="mt-3"><h5>Financial Information</h5></Col>
                                    <Col md={4}>
                                        <Label>Annual Income</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.annualIncome && 'is-invalid')}
                                            name="step2.annualIncome"
                                            type="select"
                                            value={formData.step2.annualIncome}
                                            onChange={handleChange}
                                        >
                                            {Object.keys(annualIncome.options).map((key) => (
                                                <option key={key} value={annualIncome.options[key].value}>{annualIncome.options[key].label}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Net Worth</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.netWorth && 'is-invalid')}
                                            name="step2.netWorth"
                                            type="select"
                                            value={formData.step2.netWorth}
                                            onChange={handleChange}
                                        >
                                            {Object.keys(netWorth.options).map((key) => (
                                                <option key={key} value={netWorth.options[key].value}>{netWorth.options[key].label}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Preferable mode of Payment</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.modeOfPayment && 'is-invalid')}
                                                type="select"
                                                name="modeOfPayment"
                                                value={formData.step2.modeOfPayment}
                                                required=""
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        formValueChange('step2', e.target.name, e.target.value)
                                                        if (e.target.value === 'Bank Wire') {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'BW')
                                                        } else if (e.target.value === 'Online') {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'ON')
                                                        } else {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'CY')
                                                        }
                                                    }
                                                }}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Bank Wire">Bank Wire</option>
                                                {/* <option value="Cash">Cash</option> */}
                                                <option value="Online">Online</option>
                                                {entity === 'FSA' && <option value="Crypto">Crypto</option>}
                                                {/* <option value="IND">IND</option> */}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Source of Income</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.sourceOfFunds && 'is-invalid')}
                                                type="select"
                                                name="sourceOfFunds"
                                                value={formData.step2.sourceOfFunds}
                                                required=""
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        formValueChange('step2', e.target.name, e.target.value)
                                                        if (e.target.value === 'Salaried') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'SAL')
                                                        } else if (e.target.value === 'Self-Employed') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'SLF')
                                                        } else if (e.target.value === 'Freelancer') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'FRE')
                                                        } else if (e.target.value === 'Inheritance') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'INH')
                                                        } else {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'UNE')
                                                        }
                                                    }
                                                }}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Salaried">Salaried</option>
                                                <option value="Self-Employed">Self-Employed</option>
                                                <option value="Freelancer">Freelancer</option>
                                                <option value="Inheritance">Inheritance</option>
                                                <option value="Unemployed">Unemployed (Housewife, Student etc)</option>
                                            </Input>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <Label for="source_Of_Income" className="text-primary fw-bold">Upload Document (Proof Of Income)
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Please provide  at least ONE  of the following documents that
                                                    Must reflect source of income trail and transaction for last 3 Calender Months
                                                    <ul>
                                                        <li>Salary - Payslip or Bank Statement (Salaried Person) </li>
                                                        <li>Self-Employeed - Any earning certificate Bank Statement of Company and document showing the relationship (Businessman) </li>
                                                        <li>Husband's/ Guardian's bank statement (In case of Housewife, student or not employed anywhere)</li>
                                                        <li>Any other document reflecting the possession of income reflecting source of income by client including but not limited to Savings, investments, gratuity, pension, documents.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-1 "}
                                            type="file"
                                            name="sourceOfIncome"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {

                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('SOURCE_OF_INCOME', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.sourceOfIncome && <span className="fs-6">SelectedFile: {formData.step2.sourceOfIncome.slice(12, 24)}</span>}
                                    </Col>
                                </Row>}

                                <Row style={{ display: "none" }}>
                                    <Col md={12} className="mt-3"><h5>Tax Information</h5></Col>
                                    <Col md={4}>
                                        <Label>Tax Residency:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (tin ? 'is-valid' : 'is-invalid')}
                                                type="select"
                                                name="taxResidency"
                                                value={formData.step2.taxResidency}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                            >
                                                <option value="">-- Select Country --</option>
                                                {countriesData.map((country, index) => (
                                                    <option key={index} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Label>TIN:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.taxIdentificationNumber && 'is-invalid')}
                                                name="taxIdentificationNumber"
                                                type="text" // Keep this as text to handle the custom validation
                                                value={formData.step2.taxIdentificationNumber}
                                                required=""
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Ensure only numeric values are set
                                                    if (/^\d*$/.test(value)) {
                                                        formValueChange('step2', e.target.name, value);
                                                    }
                                                }}
                                                placeholder="TIN"
                                            />
                                        </div>
                                    </Col>


                                </Row>
                                {/* <Row>
                                    <Col md={4}>
                                        <Label>Are you the beneficial owner (UBO)?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="ubo"
                                                    id="beneficialOwnerYes"
                                                    value="Yes"
                                                    checked={formData.step2.ubo === 'Yes'}
                                                    onChange={(e) => formValueChange('step2', e.target.name, e.target.value)} />
                                                <Label className="form-check-label" htmlFor="beneficialOwnerYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="ubo"
                                                    id="beneficialOwnerNo"
                                                    value="No"
                                                    checked={formData.step2.ubo === 'No'}
                                                    onChange={(e) => formValueChange('step2', e.target.name, e.target.value)} />
                                                <Label className="form-check-label" htmlFor="beneficialOwnerNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step2.ubo === 'No' && (
                                        <Col md={4}>
                                            <Label> Please state details of UBO</Label>
                                            <div className="input-group mb-3">
                                                <Input className={"form-control " + (check && !formData.step2.uboState && 'is-invalid')}
                                                    name="uboState"
                                                    type="text"
                                                    value={formData.step2.uboState}
                                                    required=""
                                                    onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                    placeholder="Enter Name and ID only" />
                                            </div>
                                        </Col>
                                    )}
                                </Row> */}
                                {!morocco && <Row>
                                    <Col xl={4}>
                                        <Label for="additional" className="w-100 d-flex align-items-center mt-3 text-primary fw-bold    "><h5>Supplementary Document (If any)</h5>
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Any Other Supporting Document</span></div>
                                        </Label>
                                        <Input
                                            className={"mb-1 optionalInput "}
                                            // className={"mb-3 " + (check && !formData.step2.additional && "is-invalid")}
                                            type="file"
                                            name="additional"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {

                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('ADDITIONAL', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.additional && <span className="fs-6">SelectedFile: {formData.step2.additional.slice(12, 24)}</span>}
                                    </Col>
                                </Row>}
                            </div>
                        }
                        {currentStep === 3 &&
                            <div className="justify w_100">
                                <Row >
                                    <Col md={12}>
                                        { morocco && <Row>
                                            <Col md={8}>
                                                <Row>
                                                    <Col md={6} >
                                                        <FormGroup>
                                                            <Label>Platform</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step3.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step3.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step3', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    {morocco && formData.step1.utmMedium === 'godoweb_ma' && <option value="MT4">MT4</option>}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} >
                                                        <FormGroup>
                                                            <Label>Account Type</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step3.accountType || formData.step3.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step3.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step3', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step3', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step4.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step4.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step4', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>Confirm Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + (validationErrors.step4.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step4.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step4', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step4.password} />
                                                </FormGroup>
                                            </Col>
                                        </Row>}
                                        { !morocco && <Row>
                                            <Col md={8}>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>Platform</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step3.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step3.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step3', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    {morocco && formData.step1.utmMedium === 'godoweb_ma' && <option value="MT4">MT4</option>}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>Account Type</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step3.accountType || formData.step3.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step3.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step3', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step3', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Label>Have you experienced trading before?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingYes"
                                                    value="Yes"
                                                    checked={formData.step3.experiencedTrading === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingNo"
                                                    value="No"
                                                    checked={formData.step3.experiencedTrading === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.experiencedTrading === true && (
                                        <>
                                            <Col md={4}>
                                                <Label>What products you have traded in?</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step3.sharesFrequency || formData.step3.sharesFrequency) ? 'is-valid' : 'is-invalid')}
                                                        type="select"
                                                        name="sharesFrequency"
                                                        value={formData.step3.sharesFrequency}
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Shares, Bonds, Equities, ETFs">Shares, Bonds, Equities, ETFs</option>
                                                        <option value="Derivatives (Future, Options, Swaps)">Derivatives (Future, Options, Swaps)</option>
                                                        <option value="Forex or Rolling Spot (FX)">Forex or Rolling Spot (FX)</option>
                                                        <option value="CFDs">CFDs</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>Frequency</Label>
                                                <div className="input-group mb-3">
                                                    <Input className={"form-control " + ((!validationErrors.step3.cfdFrequency || formData.step3.cfdFrequency) ? 'is-valid' : 'is-invalid')}
                                                        name="cfdFrequency"
                                                        type="select"
                                                        value={formData.step3.cfdFrequency}
                                                        required=""
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Rarely: 1 to 25 Trades A Year">Rarely: 1 to 25 Trades A Year</option>
                                                        <option value="Sometime: 25 to 100 Trades A Year">Sometime: 25 to 100 Trades A Year</option>
                                                        <option value="Often: Over a 100 Trades A Year">Often: Over a 100 Trades A Year</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                    {formData.step3.experiencedTrading === false && (
                                        <Col md={12}>
                                            <div className="form-check">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="learn"
                                                    id="wantToLearn"
                                                    checked={!formData.step3.experiencedTrading ? formData.step3.learn : false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, e.target.checked)} />
                                                <Label className="form-check-label" htmlFor="wantToLearn">
                                                    I want to learn, and I have understood all risks associated with this trading platform.
                                                </Label>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Label>Will this account be traded by anyone other than you?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersYes"
                                                    value="Yes"
                                                    checked={formData.step3.accountTradedByOthers === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersNo"
                                                    value="No"
                                                    checked={formData.step3.accountTradedByOthers === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.accountTradedByOthers === true && (
                                        <Col md={4}>
                                            <Label>Please attach Power of Attorney ( <a href="https://www.godocm.com/limited-power-of-attorney-account-manager/" target="_blank" rel="noopener noreferrer">Link</a>) </Label>
                                            <div className="input-group mb-3">
                                                <Input className={"form-control-file " + ((!validationErrors.step3.powerOfAttorney || formData.step3.powerOfAttorney) ? 'is-valid' : 'is-invalid')}
                                                    name="powerOfAttorney"
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                    onChange={(e) => {
                                                        const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                        const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                        const fileName = e.target.value;
                                                        const file = e.target.files[0];
                                                        const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                        if (file) {
                                                            if (file.size > maxFileSize) {
                                                                alert('File size exceeds the limit of 5 MB.');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else if (fileExtension.includes(fileExtensionCheck)) {
                                                                setFileData(e.target.value);
                                                                updateListFunction('POWER_OF_ATTORNEY', [e.target.files[0]]);
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else {
                                                                alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            {formData.step3.powerOfAttorney && <span className="fs-6">SelectedFile: {formData.step3.powerOfAttorney.slice(12, 24)}</span>}
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={4} className="d-flex align-items-center justify-content-start">
                                        <div className="input-group mb-3 w-auto">
                                            <div className="form-check form-check-inline mt-2 me-1">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="acceptTradingTerms"
                                                    id="acceptTradingTermsYes"
                                                    value="Yes"
                                                    checked={formData.step3.acceptTradingTerms === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                {/* <Label className="form-check-label" htmlFor="acceptTradingTermsYes">Yes</Label> */}
                                            </div>
                                        </div>
                                        <Label>I accept electronic trading terms</Label>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 4 && (
                            <div className="justify w_100">
                                <Row >
                                    <Col md={12}>
                                        <Row>
                                            <Col md={8}>
                                                <Row>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step4.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step4', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>Confirm Password</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + ((!validationErrors.step4.confirmPassword || formData.step4.confirmPassword) ? 'is-valid' : 'is-invalid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step4.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step4', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4} className="d-block d-sm-none">
                                                        <FormGroup>
                                                            <PasswordValidation password={formData.step4.password} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4} className="d-none d-md-block" >
                                                        <Label><h6>FATCA:</h6> Are you a US Citizen and subject to tax?</Label>
                                                        <div className="input-group mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <Input className="form-check-input"
                                                                    type="radio"
                                                                    name="usCitizen"
                                                                    id="usCitizenNo"
                                                                    value="No"
                                                                    checked={formData.step4.usCitizen === false}
                                                                    onChange={(e) => formValueChange('step4', e.target.name, false)} />
                                                                <Label className="form-check-label" htmlFor="usCitizenNo">No</Label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <Input className="form-check-input"
                                                                    type="radio"
                                                                    name="usCitizen"
                                                                    id="usCitizenYes"
                                                                    value="Yes"
                                                                    checked={formData.step4.usCitizen === true}
                                                                    onChange={(e) => formValueChange('step4', e.target.name, true)} />
                                                                <Label className="form-check-label" htmlFor="usCitizenYes">Yes</Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {formData.step4.usCitizen === true && (
                                                        <Col md={4} className="d-none d-md-block" >
                                                            <Label>TIN</Label>
                                                            <div className="input-group mb-3">
                                                                <Input
                                                                    className={"form-control " + ((!validationErrors.step2.taxIdentificationNumber || formData.step2.taxIdentificationNumber) ? 'is-valid' : 'is-invalid')}
                                                                    name="taxIdentificationNumber"
                                                                    type="text"
                                                                    value={formData.step2.taxIdentificationNumber}
                                                                    required=""
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (/^\d*$/.test(value)) { // Only allow numbers
                                                                            formValueChange('step2', e.target.name, value);
                                                                        }
                                                                    }}
                                                                    placeholder="Enter TIN"
                                                                />
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col md={4} className="d-none d-sm-block">
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step4.password} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={4} className="d-block d-md-none" >
                                        <Label><h6>FATCA:</h6> Are you a US Citizen and subject to tax?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenNo"
                                                    value="No"
                                                    checked={formData.step4.usCitizen}
                                                    onChange={(e) => formValueChange('step4', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="usCitizenNo">No</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenYes"
                                                    value="Yes"
                                                    checked={formData.step4.usCitizen === true}
                                                    onChange={(e) => formValueChange('step4', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="usCitizenYes">Yes</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step4.usCitizen === true && (
                                        <Col md={4}>
                                            <Label>TIN</Label>
                                            <div className="input-group mb-3">
                                                <Input
                                                    className={"form-control " + ((!validationErrors.step2.taxIdentificationNumber || formData.step2.taxIdentificationNumber) ? 'is-valid' : 'is-invalid')}
                                                    name="taxIdentificationNumber"
                                                    type="text"
                                                    value={formData.step2.taxIdentificationNumber}
                                                    required=""
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) { // Only allow numbers
                                                            formValueChange('step2', e.target.name, value);
                                                        }
                                                    }}
                                                    placeholder="Enter TIN"
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row> */}
                                <Row>
                                    <Col md={4}>
                                        <Label><h6>Politically Exposed Person (PEP):
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextPEP">
                                                    PEP are Natural persons who are or have been entrusted with prominent public functions in the State or any other foreign country such as Heads of States or Governments, senior politicians, senior government officials, judicial or military officials, senior executive managers of state-owned corporations, and senior officials of political parties and persons who are, or have previously been, entrusted with the management of an international organisation or any prominent function within such an organisation; and also includes:
                                                     Direct family members (of the PEP, who are spouses, children, spouses of children, parents).
                                                     Associates known to be close to the PEP, which include:
                                                    ‒ Individuals having joint ownership rights in a legal person or arrangement or any other close Business Relationship with the PEP.
                                                    ‒ Individuals having individual ownership rights in a legal person or arrangement established in favour of the PEP.
                                                </span>
                                            </div>
                                        </h6>
                                            Are you a PEP?
                                        </Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepNo"
                                                    value="No"
                                                    checked={formData.step4.politicallyExposed === false}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, false); formValueChange('step4', 'beneficialOwnerCode', 'N') }} />
                                                <Label className="form-check-label" htmlFor="pepNo">No</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepYes"
                                                    value="Yes"
                                                    checked={formData.step4.politicallyExposed === true}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, true); formValueChange('step4', 'beneficialOwnerCode', 'Y') }} />
                                                <Label className="form-check-label" htmlFor="pepYes">Yes</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <h6>
                                            Declaration:
                                        </h6>
                                        <p>
                                            Prior to submitting this application form, it is essential that you have read and understood the following terms and conditions that will govern your trading account with GODO LTD (“GoDo”), and which together with this application form, comprise our ‘Client Agreement’ with you.</p>
                                        <Col md={12}>
                                            <div className="input-group mb-3">
                                                <label className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        name="declaration"
                                                        checked={true}
                                                        onChange={(e) => {
                                                            formValueChange('step4', e.target.name, true)
                                                            //setDeclaration(!declaration)
                                                        }}
                                                    />
                                                    &nbsp;&nbsp;&nbsp; I hereby declare the following:
                                                </label>
                                            </div>
                                        </Col>
                                        <ul>
                                            <li className="linkList">
                                                <p style={{ margin: "0px" }}>I agree that I have access to all information and duly understood:
                                                </p>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Website-Terms-Conditions.pdf" target="_blank" rel="noopener noreferrer"  >Terms & Conditions;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Client-Complaint-Policy.pdf" target="_blank" rel="noopener noreferrer" >Complaint Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Conflicts-of-Interest-Policy.pdf" target="_blank" rel="noopener noreferrer" >Conflicts Of Interest Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Cookies-Policy-form.pdf" target="_blank" rel="noopener noreferrer" >Cookies Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Credit-Card-Disclaimer.pdf" target="_blank" rel="noopener noreferrer" >Credit Card Disclaimer;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Execution-Policy.pdf" target="_blank" rel="noopener noreferrer" >Execution Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/04/Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer" >Privacy Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Withdrawal-and-refund-policy-.pdf" target="_blank" rel="noopener noreferrer"  >Withdrawal & Refund;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Risk-Warning.pdf" target="_blank" rel="noopener noreferrer" >Risk Warning</a>
                                            </li>
                                            <li>I understand the risk of margin trading and aware that a significant loss greater than the initially deposit account can be incurred</li>
                                            <li>I confirm that all the information provided is correct and in case of any change I am responsible to notify GoDo in written.</li>
                                        </ul>
                                        <Col md={12}>
                                            <div className="input-group mb-3">
                                                <label className="d-flex align-items-start justify-content-start">
                                                    <input
                                                        type="checkbox"
                                                        name="agreement"
                                                        style={{ marginTop: '7px' }}
                                                        checked={true}
                                                    />
                                                    <p className="mb-0 ms-3">
                                                        {
                                                            entity === 'FSA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Authority (FSA), Saint Vincent." :
                                                                entity === 'SCA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Securities Commodities Authority (SCA), UAE." :
                                                                    "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Commission (FSC), Mauritius."

                                                        }
                                                    </p>
                                                </label>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        )
                        }
                        <div className="justify align-items-center d-flex flex-column flex-sm-row gap-y-2 mt-3 ">
                            {currentStep === 1 ? '' : <Button onClick={(e) => submitForm(e, 'previous')} className='primary-bg-color col buttonWidth mobile-disabled' style={{ height: '50px' }}>
                                Previous
                            </Button>}
                            <Button type="submit" onClick={currentStep === 4 && morocco === false ? (e) => submitForm(e, 'submit') : currentStep === 3 && morocco === true ? (e) => submitForm(e, 'submit') : (e) => submitForm(e, 'next')} className='primary-bg-color col buttonWidth' style={{ height: '50px', background: '#26539F' }}>
                                {currentStep === 4 && morocco === false ? BUTTON_TEXT.launch : currentStep === 3 && morocco === true ? BUTTON_TEXT.launch : BUTTON_TEXT.default}
                            </Button>
                        </div>
                        <div className="mt-3">
                            <Row md={12}>
                                <Col>
                                    <p className="text-danger"><b>Exercise caution:</b> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.</p>
                                </Col>
                            </Row>
                        </div>
                    </CustomForm>
                </>}
        </div >
    )
}
